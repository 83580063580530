.ranklist {
  width: 100%;
  background: #141422;
  &__results {
    padding: 2em 3em;

    .infinite-scroll-component {
      overflow-x: hidden !important;
      overflow-y: hidden !important;
    }

    .nft-card {
      max-width: 180px;
      //   border: 2px solid #5e2781;
      border-radius: 15px;
      background-color: #060612;
      padding: 0px 10px;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;

      &:hover {
        transform: translateY(-0.375rem);
      }

      &__rank {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0px;

        h1 {
          font-size: 1em;
          color: #cb79ff;
          margin-bottom: 0px;
          margin-left: 5px;
          letter-spacing: 1.5px;
          text-shadow: 0 0 2px #5e2781;
        }
      }

      &__picture {
        cursor: pointer;
        img {
          border-radius: 15px;
        }
      }

      &__name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0px;
        h3 {
          margin-bottom: 0px;
          margin-left: 5px;
          color: #ffffff;
          font-size: 0.9em;
        }
      }
      &__price {
        margin-left: 5px;
        padding-bottom: 10px;
        color: #cb79ff;
        font-size: 0.8em;
      }
    }
  }
}
