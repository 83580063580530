.nftmodal {
  background-color: #04040e !important;

  .modal-header {
    border-bottom: 0px solid #dee2e6;
    .btn-close {
      background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FF1442'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
        center/1em auto no-repeat;
    }
  }
  .modal-footer {
    border-top: 0px solid #dee2e6;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    /* align-content: space-between; */
    justify-content: center;
  }

  &__card {
    width: 50%;
    img {
      border-radius: 15px;
    }
    h4 {
      color: #cb79ff;
      text-shadow: 2px 1px 3px #000000;
      text-align: center;
    }
    h1 {
      margin-top: 15px;
      color: white;
      text-align: center;
    }
  }
  &__rarity {
    width: 50%;
    padding: 15px;

    .arwes-blockquote__content {
      .arwes-text {
        display: flex;
        justify-content: center;
        flex-grow: 1;
      }

      .attBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .attValue {
        text-align: center;
      }
    }
  }

  .loadingBars {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
}
