.wrapper {
  display: grid;
  grid-template-columns: 400px auto;
  grid-template-rows: auto;

  .sidebar {
    display: flex;
    border-right: 5px solid #5e2781;
    height: 100%;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;

    .accordion {
      width: 95% !important;
    }

    &__overlay {
      width: 100%;
      padding: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-size: contain;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);

      img {
        width: 280px;
        padding-bottom: 25px;
      }

      h1 {
        color: white;
        margin: 0 auto;
        font-size: 1.4em;
      }

      .accordion-flush {
        width: 100% !important;
      }

      .accordion-item {
        background-color: #141422;
        border: 2px solid #5e2781;

        :hover {
          background-color: #240e31;
        }
      }

      .accordion-body {
        padding: 0.5rem 0.6rem;

        input {
          width: auto;
          margin-right: 5px;
        }
        label {
          display: flex;
          align-items: center;
        }

        .row > * {
          padding-right: calc(var(--bs-gutter-x) * 0.1);
          padding-left: calc(var(--bs-gutter-x) * 0.1);
        }
      }

      .accordion-button {
        font-size: 1.1rem;
        padding: 0.5rem 1.25rem;
        color: #ffffff;
        text-align: left;
        background-color: #141422;
      }

      .accordion-button:not(.collapsed) {
        color: #ffffff;
        background-color: #833eaf;
      }

      .inventory {
        width: 80%;
        margin: 5px auto;
        padding: 20px;
        box-sizing: border-box;
        border: 2px solid #5e2781;
        position: relative;
      }

      .stargrid {
        background: #141422;
      }
    }
  }
  .main {
    display: flex;
  }
}
